import React from "react";
import { createRoot } from "react-dom/client";
import {
  coinbaseWallet,
  embeddedWallet,
  metamaskWallet,
  smartWallet,
  ThirdwebProvider,
  walletConnect,
} from "@thirdweb-dev/react";
import { Provider } from "react-redux";
import { store } from "./state";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Map } from "./pages/gardenMap";
import { PurpleForest } from "./pages/purpleForest";
import { Salon } from "./pages/salon";
import { FruitMarket } from "./pages/fruitMarket";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5";
import {
  NETWORK_ID,
  TW_FACTORY_ADDRESS,
  WEB3_MODAL_CONFIG,
  WEB3_MODAL_METADATA,
  WEB3_MODAL_PROJECT_ID,
} from "./config/config";

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata: WEB3_MODAL_METADATA }),
  chains: [WEB3_MODAL_CONFIG[NETWORK_ID]],
  projectId: WEB3_MODAL_PROJECT_ID,
});

const config = {
  factoryAddress: TW_FACTORY_ADDRESS,
  gasless: true,
};

// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.
const container = document.getElementById("root");
const root = createRoot(container!);

const isMobile = window.matchMedia("(max-width: 767px)").matches;

const wallets = [
  smartWallet(embeddedWallet(), config),
  smartWallet(metamaskWallet({ recommended: true }), config),
  smartWallet(coinbaseWallet(), config),
  smartWallet(walletConnect(), config),
];

root.render(
  <React.StrictMode>
    <ThirdwebProvider
      activeChain={NETWORK_ID}
      clientId={process.env.REACT_APP_THIRD_WEB_CLIENT_ID}
      supportedWallets={wallets}
    >
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Map isMobile={isMobile} />}></Route>
            <Route
              path="/purple-forest"
              element={<PurpleForest isMobile={isMobile} />}
            ></Route>
            <Route
              path="/salon"
              element={<Salon isMobile={isMobile} />}
            ></Route>
            <Route
              path="/fruit-market"
              element={<FruitMarket isMobile={isMobile} />}
            ></Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    </ThirdwebProvider>
  </React.StrictMode>,
);
