import { useState } from "react";
import { Link } from "react-router-dom";

import Menu from "../../images/map/menu.svg";
import skyBg1 from "../../images/map/sky-bg-1.webp";
import skyBg2 from "../../images/map/sky-bg-2.webp";
import skyBg3 from "../../images/map/sky-bg-3.webp";
import mainIslandUp2 from "../../images/map/main-island-up-2.png";
import mainIslandUp3 from "../../images/map/Group 5.webp";
import i1 from "../../images/map/i1.png";
import i2 from "../../images/map/i2.png";
import i3 from "../../images/map/i3.png";
import i4 from "../../images/map/i4.png";
import i5 from "../../images/map/i5.png";
// import i6 from "../images/i6.png";
import i7 from "../../images/map/i7.png";
import i8 from "../../images/map/i8.png";
import i9 from "../../images/map/i9.png";
import i10 from "../../images/map/i10.png";
import i11 from "../../images/map/i11.png";
import i12 from "../../images/map/i12.png";
import i13 from "../../images/map/i13.png";

export function MobileView() {
  const [menuClicked, setMenuClicked] = useState(false);

  return (
    <div className={`sm-container ${menuClicked ? "menu-clicked" : ""}`}>
      <div
        className="sm-map-top-menu"
        id="sm-menu"
        onClick={() => setMenuClicked(!menuClicked)}
      >
        <img src={Menu} alt="Menu" />
      </div>
      <div className="sm-map-menu-overlay">
        <div className="sm-map-menu-overlay__container">
          <a
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-1"
            href="https://lore.arkadiapark.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div data-menu-item="1">
              <span>
                Castle of <br /> Lore
              </span>
            </div>
          </a>
          <Link
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-2"
            to="/salon"
          >
            <div data-menu-item="2">
              <span>Salon</span>
            </div>
          </Link>
          <a
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-3"
            href="https://salon.arkadiapark.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div data-menu-item="3">
              <span>Honey</span>
            </div>
          </a>
          {/* <div data-menu-item='4' className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-4">
            <span>Bridge</span>
          </div> */}
          <a
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-5"
            href="https://dao.arkadiapark.com/#"
            target="_blank"
            rel="noreferrer"
          >
            <div data-menu-item="4">
              <span>DAO ARK</span>
            </div>
          </a>
          <Link
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-6"
            href="/purple-forest"
          >
            <div data-menu-item="4">
              <span style={{ right: "-50px" }}>The Purple <br /> Forest</span>
            </div>
          </Link>
          <a
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-7"
            href="https://seed.arkadiapark.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div data-menu-item="5">
              <span>
                Seed <br /> Deposit
              </span>
            </div>
          </a>
          <Link
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-8"
            to="/fruit-market"
          >
            <div data-menu-item="6">
              <span>Fruit Market</span>
            </div>
          </Link>
          <a
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-9"
            href="/"
            rel="noreferrer"
          >
            <div data-menu-item="7">
              <span>Bridge</span>
            </div>
          </a>
          <a
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-10"
            href="https://market.arkadiapark.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div data-menu-item="7">
              <span>Auction House</span>
            </div>
          </a>
          <a
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-11"
            href="/"
            rel="noreferrer"
          >
            <div data-menu-item="7">
              <span>Burner</span>
            </div>
          </a>

          <a
            disabled
            className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-12"
            href="/"
            rel="noreferrer"
          >
            <div data-menu-item="7">
              <span>Arcade</span>
            </div>
          </a>
          <a className="sm-map-menu-overlay__menu sm-map-menu-overlay__menu--item-13"
            href="https://experience.arkadiapark.com//"
            target="_blank"
            rel="noreferrer"
          >
            <div data-menu-item="7">
              <span>Arkadia <br /> Experience</span>
            </div>
          </a>














        </div>
      </div>
      <div className="sm-map-items">
        <img src={i1} alt="Salon UI" disabled />
        <img src={i2} alt="Lore" />
        <img src={i3} alt="Arkadia DAO" />
        <img src={i4} alt="" disabled />
        {/* <img src="i6.png" alt="" /> */}
        <img src={i5} alt="Purple Forest UI" disabled />
        <img src={i7} alt="Fruit Market UI" disabled />
        <img src={i8} alt="" disabled />
        <img src={i9} alt="" disabled />
        <img src={i10} alt="" />
        <img src={i11} alt="" disabled />
        <img src={i12} alt="" disabled />
        <img src={i13} alt="" />
      </div>
      <div className="sm-map-bg">
        <img src={mainIslandUp3} alt="" />
      </div>
      <div className="sm-map-bg-sky">
        <img src={skyBg1} alt="" />
        <img src={skyBg3} alt="" />
        <img src={skyBg2} alt="" />
      </div>
    </div>
  );
}
