import { useState, createRef } from "react";
import Speaker from "../images/map/speaker.png";
import SpeakerMuted from "../images/map/speaker-mute.png";
import styles from "./playAudio.module.css";

const PlayAudio = ({ music }: { music: any }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = createRef<HTMLAudioElement>();

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={styles.container}>
      <audio src={music} ref={audioRef} />
      <button
        className={`${styles.playButton} ${!isPlaying ? "muted" : ""}`}
        onClick={togglePlay}
      >
        {isPlaying ? (
          <img className={styles.playImg} src={Speaker} alt="Speaker" />
        ) : (
          <img
            className={styles.playImg}
            src={SpeakerMuted}
            alt="Speaker muted"
          />
        )}
      </button>
    </div>
  );
};

export default PlayAudio;
