import { useState, useEffect } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import { Link } from "react-router-dom";
import { MobileView } from "./mobileView";
import UiControls from "./uiControls";
import "./styles/garden.css";

import skyBg1 from "../../images/map/sky-bg-1.webp";
import skyBg2 from "../../images/map/sky-bg-2.webp";
import skyBg3 from "../../images/map/sky-bg-3.webp";
import skyBg4 from "../../images/map/sky-bg-4.webp";
import skyBg5 from "../../images/map/Evening sky.jpg";
import skyBg6 from "../../images/map/sky-bg-2.webp";
import skyBg7 from "../../images/map/sky-bg-3.webp";


import bl1 from "../../images/map/bl-1.png";
import bl2 from "../../images/map/bl-2.png";
import bl3 from "../../images/map/bl-3.png";
import mainIslandUp3 from "../../images/map/main-island-up-3.webp";
import mainIslandUp4 from "../../images/map/main-island-i4.webp";
import i1 from "../../images/map/i1.png";
import i2 from "../../images/map/i2.png";
import i3 from "../../images/map/i3.png";
import i4 from "../../images/map/i4.png";
import i5 from "../../images/map/i5.png";
// import i6 from "../images/i6.png";
import i7 from "../../images/map/i7.png";
import i8 from "../../images/map/i8.png";
import i9 from "../../images/map/i9.png";
import i10 from "../../images/map/i10.png";
import i11 from "../../images/map/i11.png";
import i12 from "../../images/map/i12.png";
import i13 from "../../images/map/i13.png";
import PurpleFren from "../../images/map/purpleFrenFrame.png";
import { useNftData } from "./useNftData";
import { MAP_PURPLE_FREN_ID } from "../../config/config";
import DigitalClock from "./digitalClock";

/**
 * @param {{ isMobile: boolean }} props
 */
export function Map({ isMobile }) {
  const [isPurpleFriendFound, setIsPurpleFriendFound] = useState(false);

  const { data } = useNftData();

  useEffect(() => {
    if (data) {
      const editionArray = data.map((item) => item.metadata.edition);
      console.log("editionArray", editionArray);
      if (editionArray.includes(MAP_PURPLE_FREN_ID)) {
        console.log("found");
        setIsPurpleFriendFound(true);
      } else {
        console.log("Not found");
        setIsPurpleFriendFound(false);
      }
    }
  }, [data]);

  useEffect(() => {
    const mainBgsDiv = document.querySelector(".main-bg");
    const menuOptDiv = document.querySelectorAll(".menu-options__item");
    const menuOptImgs = document.querySelectorAll(".main-bg__asset-items img");
    const mainBgSky = document.querySelector(".main-bg__sky");
    const mainBgSkyNight = document.querySelector(".sky-night");
    const mainFrontSprites = document.querySelector(".front-spites");

    [...menuOptDiv].forEach((item, i) => {
      console.log(item);

      item.addEventListener("mouseenter", (e) => {
        console.log("triggered", item.getAttribute("data-menu-item").slice(-1));
        [...menuOptImgs][i].classList.add("item-hovered");
        item.classList.add("item-hovered");
        mainBgsDiv.classList.add("item-hovered");
        mainBgSky.classList.add("item-hovered");
        mainFrontSprites.classList.add("item-hovered");
        mainBgSkyNight.classList.add("item-hovered");
      });

      item.addEventListener("mouseleave", (e) => {
        console.log("Left");
        [...menuOptImgs][i].classList.remove("item-hovered");
        item.classList.remove("item-hovered");
        mainBgsDiv.classList.remove("item-hovered");
        mainBgSky.classList.remove("item-hovered");
        mainFrontSprites.classList.remove("item-hovered");
        mainBgSkyNight.classList.remove("item-hovered");

      });
    });
  }, []);

  const [isDay, setIsDay] = useState();

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    // Nighttime (6pm to 5:59am)
    if (hour < 6 || hour >= 18) {
      setIsDay(false)
    } else {
      setIsDay(true)
    }

  }, []);

  return (
    <>
      <div className="garden-map-container">
        <DigitalClock />
        <div>
          {/* Connect wallet hided */}
          {/* <div className="wallet-options">
            <ConnectWallet
              theme={"light"}
              switchToActiveChain={true}
              modalSize={"compact"}
              hideSwitchToPersonalWallet={true}
            />
          </div> */}

          {!isMobile && (
            <div className="main-bg">
              <div className="front-spites">
                <img className="fs-sp-1" src={skyBg4} alt="island" />
                <div className="fs-absolute">
                  <img className="fs-blsp-1" src={bl1} alt="island" />
                  <img className="fs-blsp-2" src={bl2} alt="island" />
                  <img className="fs-blsp-3" src={bl3} alt="island" />
                </div>
              </div>

              <div className="main-bg__img">
                {/* <img src="island-main2.png" alt="island" /> */}
                <img src={mainIslandUp4} alt="island" />
                {/* <img src="sky-bg-4.png" alt="island" /> */}

                <div className="main-bg__container">
                  <div className="main-bg__asset-items" disabled>
                    <img className="ai-1" src={i1} alt="Salon UI" />
                  </div>
                  <div className="main-bg__asset-items " >
                    <img className="ai-2" src={i2} alt="" />
                  </div>
                  <div className="main-bg__asset-items "  >
                    <img className="ai-3" src={i3} alt="Arkadia DAO"  />
                  </div>
                  <div className="main-bg__asset-items " disabled>
                    <img className="ai-4" src={i4} alt="" />
                  </div>
                  {/* <div className='main-bg__asset-items '>
                  <img className='ai-5' src="i6.png" alt="" />
                </div> */}
                  <div className="main-bg__asset-items " disabled>
                    <img className="ai-6" src={i5} alt="Purple Forest UI" />
                  </div>
                  <div className="main-bg__asset-items " disabled>
                    <img className="ai-7" src={i7} alt="Fruit Market UI" />
                  </div>
                  <div className="main-bg__asset-items " disabled>
                    <img className="ai-7" src={i8} alt="" />
                  </div>
                  <div className="main-bg__asset-items " disabled>
                    <img className="ai-7" src={i9} alt="" />
                  </div>
                  <div className="main-bg__asset-items ">
                    <img className="ai-7" src={i10} alt="" />
                  </div>
                  <div className="main-bg__asset-items " disabled>
                    <img className="ai-7" src={i11} alt="" />
                  </div>
                  <div className="main-bg__asset-items " disabled>
                    <img className="ai-7" src={i12} alt="" />
                  </div>
                  <div className="main-bg__asset-items " >
                    <img className="ai-7" src={i13} alt="" />
                  </div>

                </div>
                {isPurpleFriendFound && (
                  <div className="main-bg__asset-items main-bg__asset-items--purle-friend">
                    <img src={PurpleFren} alt="Purple Fren" />
                  </div>
                )}
              </div>

              <div className="menu-options">
                <Link to="/salon" disabled>
                  <div
                    data-menu-item="dm-1"
                    className="menu-options__item menu-options__item--i1"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Salon</h4>
                        <p>
                          Get enough strand of hair
                          <br /> NFTs to mint your FREN
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <a
                  href="https://lore.arkadiapark.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    data-menu-item="dm-2"
                    className="menu-options__item menu-options__item--i2"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Castle of Lore</h4>
                        <p>Learn about the history of Arkadia</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="https://dao.arkadiapark.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    data-menu-item="dm-3"
                    className="menu-options__item menu-options__item--i3"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>DAO ARK</h4>
                        <p> Where decisions are being taken</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="https://honey.arkadiapark.com/"
                  target="_blank"
                  rel="noreferrer"
                  disabled
                >
                  <div
                    data-menu-item="dm-4"
                    className="menu-options__item menu-options__item--i4"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Honey</h4>
                        <p>Borrow & Lend NFTs</p>
                      </div>
                    </div>
                  </div>
                </a>
                {/* <div data-menu-item='dm-5' className='menu-options__item menu-options__item--i5'>
                <div className='menu-tip'>
                  <div className='menu-tip__info'>
                    <h4>Bridge</h4>
                    <p>This is some sample Text</p>
                  </div>
                </div>
              </div> */}
                <Link to="/purple-forest" disabled>
                  <div
                    data-menu-item="dm-6"
                    className="menu-options__item menu-options__item--i6"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>The Purple Forest</h4>
                        <p>Grow Purple Trees</p>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/fruit-market" disabled>
                  <div
                    data-menu-item="dm-7"
                    className="menu-options__item menu-options__item--i7"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Fruit Market</h4>
                        <p>Trade your Purple Fruits</p>
                      </div>
                    </div>
                  </div>
                </Link>

                <a
                  href="https://seed.arkadiapark.com/"
                  target="_blank"
                  rel="noreferrer"
                  disabled
                >
                  <div
                    data-menu-item="dm-8"
                    className="menu-options__item menu-options__item--i8"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Seed Deposit</h4>
                        <p>Mint Seeds Weekly</p>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="/" target="_blank" rel="noreferrer" disabled>
                  <div
                    data-menu-item="dm-9"
                    className="menu-options__item menu-options__item--i9"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Bridge</h4>
                        <p>Go to the next Isle</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="http://market.arkadiapark.com/" target="_blank" rel="noreferrer" >
                  <div
                    data-menu-item="dm-9"
                    className="menu-options__item menu-options__item--i10"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Auction House</h4>
                        <p>A place where you can Bid-to-Earn. Here everybody wins!</p>
                      </div>
                    </div>
                  </div>
                </a>
                <a disabled href="/" target="_blank" rel="noreferrer" >
                  <div
                    data-menu-item="dm-9"
                    className="menu-options__item menu-options__item--i11"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Burner</h4>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="/" target="_blank" rel="noreferrer" disabled>
                  <div
                    data-menu-item="dm-9"
                    className="menu-options__item menu-options__item--i12"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Arcade</h4>
                        <p>Welcome to the Arcade! </p>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="http://experience.arkadiapark.com/" target="_blank" rel="noreferrer" >
                  <div
                    data-menu-item="dm-9"
                    className="menu-options__item menu-options__item--i13"
                  >
                    <div className="menu-tip">
                      <div className="menu-tip__info">
                        <h4>Arkadia Experience</h4>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </a>


              </div>

              <UiControls />

              <div className="main-bg__sky">
                {/* <div className='main-bg__flags'>
                <img src="flags/flasg-1.svg" alt="" />
                <img src="flags/flasg-1.svg" alt="" />
              </div> */}
                <img className="mb-sk-1" src={skyBg1} alt="island" />
                <img className="mb-sk-2" src={skyBg2} alt="island" />
                <img className="mb-sk-3" src={skyBg3} alt="island" />
              </div>

              <div className={`main-bg__sky sky-night ${isDay ? ' d-none' : ''}`}>
                <img className='mb-sk-1' src={skyBg5} alt="island" />
                <img className='mb-sk-2' src={skyBg6} alt="island" style={{ 'opacity': '0.5' }} />
                <img className='mb-sk-3' src={skyBg7} alt="island" style={{ 'opacity': '0.5' }} />
              </div>

            </div>
          )}

          {isMobile && <MobileView />}
        </div>
      </div>
    </>
  );
}
